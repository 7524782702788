@use 'settings' as *;

.input-group {
  width: auto;
  text-align: left;
}

label {
  width: auto;
  text-align: left;
}

.placeholder {
  color: #c2c2c2;
  font-style: italic;
}

input[type='password'],
input[type='date'],
input[type='number'],
input[type='tel'],
textarea,
select,
input[type='text'] {
  outline: none;
  font-size: $font-size;
  font-family: $font-family;

  //coma seperation is not working on placeholders.
  //if first placeholder is not working it will skip the rest...
  &::-webkit-input-placeholder {
    color: #c2c2c2;
    font-style: italic;
  }

  &::-moz-placeholder {
    color: #c2c2c2;
    font-style: italic;
  }

  &::-ms-placeholder {
    color: #c2c2c2;
    font-style: italic;
  }

  &::placeholder {
    color: #c2c2c2;
    font-style: italic;
  }

  &::-webkit-inner-spin-button {
    transform: translateX(8px);
  }

  &:disabled {
    cursor: not-allowed;
    color: color($neutral-palette, 600);
  }
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

.settings-group {
  h1,
  h2,
  h3 {
    margin-bottom: #{$margin * 2}px;
  }
}

.big-input {
  width: 100%;
  position: relative;

  input,
  select {
    min-height: 46px;
    width: 100%;
    font-size: 1rem;
    padding: 0 #{$padding * 2}px;
    border: 1px solid $border-color;
    border-radius: 4px;
    outline: none;

    &:focus,
    &:active {
      border-color: color($primary-palette);
    }

    &.error {
      border-color: color($error-palette, 500);
    }
  }

  &:after {
    border-radius: 2px;
    width: 4px;
    height: 46px;
    content: '';
    position: absolute;
    bottom: 0;
    left: -12px;
    background: color($primary-palette, 300);
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms;
  }

  &.has-change {
    &:after {
      opacity: 1;
    }
  }

  &.error {
    input,
    select {
      opacity: 1;
      background: color($error-palette, 500);
    }
  }

  select {
    background: $white-color url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEzIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS4yODQ1IDFMNi4zODQ1MiA1Ljg5OTU1QzYuMzIwMzEgNS45NjM4NiA2LjIzMzE1IDYgNi4xNDIyNyA2QzYuMDUxMzggNiA1Ljk2NDIzIDUuOTYzODYgNS45MDAwMSA1Ljg5OTU1TDEgMSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K') no-repeat right 14px center;
    appearance: none;
  }

  .units {
    position: absolute;
    right: 12px;
    bottom: 16px;
    font-size: 0.812rem;
    pointer-events: none;
    user-select: none;
    color: color($neutral-palette, 600);
  }

  &.select-version {
    select {
      appearance: none;
      -webkit-appearance: none;
    }

    select::-ms-expand {
      display: none;
    }

    &::after {
      grid-area: select;
      content: '';
      width: 8px;
      height: 8px;
      right: 17px;
      bottom: 21px;
      border: solid $black-color;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: absolute;
    }
  }
}

.upload-button {
  background: color($primary-palette, 500);
  padding: 12px #{$padding * 3}px;
  min-height: 40px;
  border-radius: 20px;
  display: block;
  cursor: pointer;
  transition: background 200ms;
  font-size: 0.812rem;
  font-weight: 600;

  &:hover {
    background: color($primary-palette, 600);
  }

  input[type='file'] {
    display: none;
  }
}

.top-search {
  margin-left: 20px;
  appearance: none;
  background: color($neutral-palette, 200) url('/assets/images/search.svg') no-repeat right 20px center;
  padding: 10px 25px;
  font-size: 0.875rem;
  font-family: $font-family;
  box-sizing: border-box;
  height: 56px;
  border: none;
  border-radius: 28px;
  width: 325px;
  max-width: calc(100vw - 80px);
  padding-right: 30px;

  &:focus {
    outline: none;
  }
}
