@use 'settings' as *;

.table {
  font-family: $font-family;
  color: $black-color;
  font-size: 0.812rem;
  text-align: left;
  border-collapse: collapse;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  th,
  td {
    padding: #{$padding}px #{$padding * 2}px;
    margin: 0;
  }

  tr {
    border-bottom: 1px solid $border-color;
  }

  th {
    font-weight: 600;

    &[ng-reflect-app-sort] {
      cursor: pointer;
      user-select: none;
    }

    &.sortable {
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: color($neutral-palette, 100);
      }

      .arrow {
        margin-left: 10px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $black-color;
        display: inline-block;
        pointer-events: none;
        opacity: 0;
        transition: opacity 200ms;

        &.arrow-active {
          opacity: 1;
        }

        &.arrow-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  .table-row:hover {
    background: color($neutral-palette, 200);

    &:not(.non-clickable) {
      cursor: pointer;
    }
  }

  td {
    color: $black-color;
  }

  .table-remove {
    cursor: pointer;
    line-height: 0.1;
    transform: translateY(5px);
    font-size: 1.4rem;
    user-select: none;

    @media screen and (max-width: $tablet) {
      transform: translateY(6px);
    }

    &:hover {
      color: color($primary-palette, 500);
    }
  }
}

.responsive-table {
  width: 100%;
  font-size: $font-size;

  .table-link {
    cursor: pointer;
  }

  thead,
  tbody {
    tr {
      transition: 300ms;

      th,
      td {
        text-align: left;
        vertical-align: top;
        border-top: 0;
        transition: 300ms;
        width: unset;
        font-size: 0.688rem;

        &.break-word {
          word-break: break-all;
        }

        @media screen and (min-width: $tablet-small) {
          font-size: 0.812rem;
        }
      }
    }
  }

  thead tr th {
    vertical-align: bottom;
    border-bottom: 1px solid $border-color;
  }

  caption + thead,
  colgroup + thead,
  thead:first-child {
    tr:first-child {
      th,
      td {
        border-top: 0;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    thead {
      display: none;
    }

    tbody {
      display: block;

      tr {
        display: block;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        margin-bottom: #{$margin * 2}px;

        td {
          display: block;
          vertical-align: middle;
          text-align: right;
        }

        td[data-title]:before {
          content: attr(data-title);
          float: left;
          padding-right: #{$padding}px;
          font-weight: 600;
        }

        th,
        td {
          border-bottom: 1px solid $border-color;
          padding: #{$padding * 1.5}px #{$padding * 2}px;
          min-height: 39px;
          word-break: break-word;

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
