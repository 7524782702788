@use 'settings' as *;

h1,
.h1 {
  font-size: 3rem;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

h2,
.h2 {
  font-size: 2.5rem;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

h3,
.h3 {
  font-size: 2.062rem;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

h4,
.h4 {
  font-size: 1.75rem;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

h5,
.h5 {
  font-size: 1.438rem;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

h6,
.h6 {
  font-size: 1.1188rem;
  line-height: $line-height;
  margin: 0;
  padding: 0;
}

p,
.p {
  font-size: 1rem;
  line-height: $line-height;
  margin: 0 0 #{$margin * 3}px 0;
  padding: 0;
}

.p-small {
  font-size: 0.812rem;
  line-height: $line-height;
}

.p-smaller {
  font-size: 0.688rem;
  line-height: $line-height;
}

.h1-bold,
.h2-bold,
.h3-bold,
.h4-bold,
.h5-bold,
.h6-bold,
.p-bold,
.p-small-bold,
.p-smaller-bold,
.bold {
  font-weight: 600;
}

a {
  color: color($primary-palette, 500);
  font-weight: 600;
  text-decoration: none;

  @media screen and (min-width: $laptop) {
    transition: color 200ms;
    &:hover {
      color: color($primary-palette, 600);
    }
  }
}
