@use 'settings' as *;

//No component needed at the moment
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: #{$margin * 4}px;

  &__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    flex: 1 1 auto;
  }

  app-breadcrumb {
    margin-bottom: #{$margin}px;
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: #{$margin}px 0;

    button {
      margin: #{$margin * 0.5}px 0 #{$margin * 0.5}px #{$margin}px;
      flex: 0 0 auto;
    }
  }
}
