@use 'settings' as *;

.owl-dt-inline-container,
.owl-dt-popup-container {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  color: $white-color;
  transition: box-shadow 200ms;
  margin-top: #{$margin}px;
  border-radius: $border-radius;

  * {
    user-select: none;
  }

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  }

  .owl-dt-calendar-table {
    .owl-dt-calendar-cell:not(.owl-dt-calendar-cell-active):hover {
      .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
        background-color: color($neutral-palette, 100);
      }
    }

    .owl-dt-calendar-cell-in-range {
      background-color: color($neutral-palette, 200);
    }

    .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
      border: 1px solid $border-color;
    }

    .owl-dt-calendar-cell-selected {
      background-color: color($primary-palette, 500);
    }

    .owl-dt-calendar-cell-selected:hover .owl-dt-calendar-cell-content,
    .owl-dt-calendar-cell-selected:hover .owl-dt-calendar-cell-selected {
      background-color: color($primary-palette, 500);
    }
  }

  .owl-dt-container-info {
    display: none;
  }
}
