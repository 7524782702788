@use 'settings' as *;

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: -4px #{$margin}px -4px 0;
  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  border: 2px solid $black-color;
  border-radius: 2px;
  transform: translateY(2px);
  display: inline-grid;
  place-content: center;

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    clip-path: polygon(41.7% 68.3%, 25% 51.7%, 30.8% 45.8%, 41.7% 56.7%, 69.2% 29.2%, 75% 35%);
    transform: scale(0);
    transform-origin: center;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: contrast($primary-palette);
  }

  &:checked {
    border-color: color($primary-palette);
    background: color($primary-palette);

    &::before {
      transform: scale(1.2);
    }

  }

  &:disabled {
    --form-control-color: var(--form-control-disabled);
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }
}

.checkboxcontainer {
  position: relative;
  padding-left: 18px;
  margin: 0;
  cursor: pointer;
  user-select: none;
  min-height: 18px;
  display: flex;
  align-items: center;

  *:disabled,
  &.disabled {
    cursor: not-allowed;
    color: color($neutral-palette, 600);
  }

  &:after {
    border-radius: 2px;
    width: 4px;
    height: 24px;
    content: '';
    position: absolute;
    left: -12px;
    background: color($primary-palette, 300);
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms;
  }

  &.has-change {
    &:after {
      opacity: 1;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    margin: 0;
    visibility: hidden;
    height: 0;
    width: 0;

    &:disabled ~ .checkmark {
      cursor: not-allowed;
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 2px;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    background: $white-color;
    border: solid 1px $border-color;
    transition: border-color 250ms;

    &:after {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      left: 5px;
      top: 1px;
      width: 4px;
      height: 10px;
      border: solid contrast($primary-palette);
      border-width: 0 2px 2px 0;
      transition:
        opacity 250ms,
        visibility 250ms,
        transform 200ms;
      opacity: 0;
      visibility: hidden;
      transform: rotate(45deg) scale(0.85);
    }
  }

  &.big-checkbox {
    box-sizing: border-box;
    padding: #{$padding * 2}px 32px;
    min-height: 24px;
    font-size: 14px;

    label {
      font-size: 14px;
    }

    .checkmark {
      height: 24px;
      width: 24px;

      &:after {
        border-width: 0 3px 3px 0;
        left: 7px;
        top: 0;
        width: 8px;
        height: 18px;
        border-color: $white-color;
      }
    }

    &:hover input ~ .checkmark {
      border-color: color($primary-palette, 400);
    }

    input:checked ~ .checkmark {
      border-color: color($primary-palette, 500);
      background: color($primary-palette, 500);
    }
  }

  &:hover input ~ .checkmark,
  input:checked ~ .checkmark {
    border-color: color($primary-palette, 500);
  }

  input:checked ~ .checkmark:after {
    opacity: 1;
    visibility: visible;
    transform: rotate(45deg) scale(1);
  }

  .checkmark:after {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    left: 5px;
    top: 1px;
    width: 4px;
    height: 10px;
    border: solid color($primary-palette, 500);
    border-width: 0 2px 2px 0;
    transition:
      opacity 250ms,
      visibility 250ms,
      transform 200ms;
    opacity: 0;
    visibility: hidden;
    transform: rotate(45deg) scale(0.85);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: $black-color;
  }
}

/* RADIO version */
.radiocontainer {
  position: relative;
  padding-left: 20px;
  margin: 0;
  cursor: pointer;
  user-select: none;
  min-height: 20px;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
  }

  *:disabled,
  &.disabled {
    cursor: not-allowed;
    color: color($neutral-palette, 600);
  }

  .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    box-sizing: border-box;
    background: $white-color;
    border: solid 2px $border-color;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: border-color 250ms;

    &:after {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      left: 2px;
      top: 2px;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background: color($primary-palette, 500);
      transition:
        opacity 250ms,
        visibility 250ms,
        transform 200ms;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.1);
    }
  }

  &:hover input ~ .radiomark,
  input:checked ~ .radiomark {
    border-color: color($primary-palette, 500);
  }

  input:checked ~ .radiomark:after {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: $black-color;
  }
}
