@use 'settings' as *;
@use '@angular/material' as mat;

@use 'maplibre-gl/dist/maplibre-gl.css';
@use '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@use '@angular/material/prebuilt-themes/deeppurple-amber.css';

@use 'styles/fonts';
@use 'styles/checkbox';
@use 'styles/buttons';
@use 'styles/header-bar';
@use 'styles/inputs';
@use 'styles/select';
@use 'styles/spinner';
@use 'styles/tables';
@use 'styles/forms';
@use 'styles/drag';
@use 'styles/toasts';
@use 'styles/date-picker';
@use '@ng-select/ng-select/themes/material.theme.css';
// regular style toast
//@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

@include mat.core();

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/PlusJakartaSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/PlusJakartaSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/PlusJakartaSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/PlusJakartaSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/PlusJakartaSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: $font-size;
  font-family: $font-family;
}

app-root {
  display: contents;
  height: 100%;
  width: 100%;
}

//overwriting material theming with own fonts/colors
$custom-primary: mat.m2-define-palette($primary-palette, 500);
$custom-accent: mat.m2-define-palette($secondary-palette, 500, 100, 400);
$custom-typography: mat.m2-define-typography-config(
  $headline-1:
    mat.m2-define-typography-level(
      3rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $headline-2:
    mat.m2-define-typography-level(
      2.5rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $headline-3:
    mat.m2-define-typography-level(
      2.062rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $headline-4:
    mat.m2-define-typography-level(
      1.75rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $headline-5:
    mat.m2-define-typography-level(
      1.438rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $headline-6:
    mat.m2-define-typography-level(
      1.1188rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $subtitle-1:
    mat.m2-define-typography-level(
      1.438rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $subtitle-2:
    mat.m2-define-typography-level(
      1.1188rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $body-1:
    mat.m2-define-typography-level(
      1rem,
      $line-height,
      500,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $body-2:
    mat.m2-define-typography-level(
      0.812rem,
      $line-height,
      500,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
  $caption:
    mat.m2-define-typography-level(
      1rem,
      $line-height,
      600,
      $font-family: '"Plus Jakarta Sans", sans-serif'
    ),
);
$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
    ),
    typography: $custom-typography,
  )
);

@include mat.all-component-themes($my-theme);

/* firefox bar styling */
body,
.styled-scroll {
  scrollbar-width: thin;
  scrollbar-color: color($neutral-palette, 300) transparent;
}

::-webkit-scrollbar {
  width: 6px;
}

/* webkit bar styling */
::-webkit-scrollbar-track,
* ::-webkit-scrollbar-track {
  background: transparent;
  width: 6px;
  transition: background 250ms;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

::-webkit-scrollbar-thumb,
* ::-webkit-scrollbar-thumb {
  transition: background 250ms;
  background: color($neutral-palette, 300);
}

::-webkit-scrollbar-thumb:hover,
* ::-webkit-scrollbar-thumb:hover {
  background: color($neutral-palette, 400);
}
