@use 'settings' as *;

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 600px;
  width: 100%;
  margin-bottom: #{$margin * 2}px;

  .form {
    margin-bottom: 0;
  }

  label,
  .label {
    font-size: 14px;
    color: color($primary-palette, 800);
    margin-bottom: #{$margin}px;
    display: block;
  }

  input[type='text'] {
    appearance: none;
    width: 100%;
    padding: 15px;
    background: color($primary-palette, 100);
    border: 1px solid color($primary-palette, 100);
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
      border-color: color($primary-palette, 600);
    }
  }

  select {
    appearance: none;
    cursor: pointer;
    width: 100%;
    padding: 15px;
    background: color($primary-palette, 100) url('/assets/images/arrow_drop_down.svg') no-repeat
      right 20px center;
    border: 1px solid color($primary-palette, 100);
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;

    &:disabled {
      background: color($primary-palette, 100) url('/assets/images/arrow_drop_down_disabled.svg')
        no-repeat right 20px center;
      cursor: not-allowed;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: #{$margin * 2}px;
    width: 100%;

    input.ng-invalid {
      border-color: color($error-palette, 500);
    }

    &.half {
      width: calc(50% - #{$margin * 1.5}px);
    }

    &.big {
      width: calc(100% - 132px);
    }

    &.small {
      width: 100px;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: #{$margin * 2}px;
      width: 100%;

      .form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: auto;
        margin-left: #{$margin * 2}px;
        width: 100%;
        flex-wrap: wrap;

        &:nth-child(1) {
          margin-left: 0;
        }

        input {
          width: 60px;
          flex: 1 1 auto;
        }
      }

      label {
        margin-right: #{$margin * 1.5}px;
        margin-left: #{$margin * 2}px;

        &:nth-child(1) {
          margin-left: 0;
        }
      }

      button {
        margin: 0 0 0 #{$margin * 2}px;
        color: color($primary-palette);
        transition: color 250ms;
        height: 50px;
        margin-bottom: auto;

        &:hover {
          color: color($primary-palette, 700);
        }
      }
    }

    .error {
      color: color($error-palette);
      font-size: 0.75rem;
      margin: #{$margin}px 0;
      width: 100%;
    }
  }

  button {
    margin-top: #{$margin * 2}px;
  }

  &:not(.ng-submitted) {
    input.ng-invalid {
      border-color: transparent;
    }

    .error {
      display: none;
    }
  }
}

//Theme for mdc inputs
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: contrast($primary-palette, 100);
}

.mat-mdc-text-field-wrapper.mdc-text-field--filled,
.mat-mdc-text-field-wrapper {
  border-radius: $border-radius;
}

.mat-mdc-chip-set .mdc-evolution-chip {
  margin: #{$margin * 0.5}px 0 #{$margin}px #{$margin}px;
  background: color($primary-palette, 300);
}

.mat-mdc-chip-remove,
button.mat-mdc-chip-remove {
  margin-top: 0;
}

mat-form-field.mat-primary .mdc-text-field--filled {
  background-color: color($primary-palette, 100);
  border: 1px solid color($primary-palette, 100);
}

.mat-mdc-form-field-focus-overlay {
  display: none;
}

.mat-focused .mdc-text-field--filled {
  border: 1px solid color($primary-palette) !important; //important is needed to override the default style...
}

.mdc-line-ripple,
.mat-ripple.mat-mdc-chip-ripple,
.mat-mdc-chip-focus-overlay {
  display: none;
}
