@use 'settings' as *;

.inline-spinner {
  border: 1px solid $border-color;
  border-left-color: $black-color;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spinner-animation 1s linear infinite;
}

.small-spinner {
  width: 25px;
  height: 25px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  background:
    linear-gradient(0deg, #00000080 50%, #000000ff 0) center/2px 100%,
    linear-gradient(90deg, #00000040 50%, #000000bf 0) center/100% 2px;
  background-repeat: no-repeat;
  animation: spinner-animation 700ms infinite steps(12);

  &::before,
  &::after {
    content: '';
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }

  &::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
}

.big-spinner {
  width: 100px;
  height: 100px;
  display: grid;
  border-radius: 50%;
  position: absolute;
  z-index: 6;
  margin: -50px 0 0 -50px;
  top: 50%;
  left: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  background:
    linear-gradient(0deg, color($secondary-palette, 500), 50%, color($secondary-palette, 600) 0)
      center/8px 100%,
    linear-gradient(90deg, color($secondary-palette, 600) 50%, color($secondary-palette, 500) 0)
      center/100% 8px;
  background-repeat: no-repeat;
  animation: spinner-animation 700ms infinite steps(12);

  &::before,
  &::after {
    content: '';
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }

  &::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
}

@keyframes spinner-animation {
  100% {
    transform: rotate(1turn);
  }
}
