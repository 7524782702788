@use 'sass:map';

@function color($color, $shade: 500) {
  @return map.get($color, $shade);
}

@function contrast($color, $shade: 500) {
  @return map.get(map.get($color, contrast), $shade);
}

$padding: 8;
$margin: 8;

$font-family: 'Plus Jakarta Sans', sans-serif;
$font-size: 16px;
$line-height: 1.4;

$border-radius: 8px;
$border-radius-round: 21px;

//Breakpoints
$mobile-small: 320px;
$mobile: 480px;
$tablet-small: 768px;
$tablet: 1024px;
$laptop: 1200px;
$desktop: 1600px;

//Colors
$black-color: #000000;
$white-color: #ffffff;
$border-color: #ddd9c6;
$primary-color-twinspot: #a8c0c3;
$secondary-color-twinspot: #d7ed4c;

:root {
  --primary-100: #f8f9fb;
  --primary-200: #e2eced;
  --primary-300: #d1dedf;
  --primary-400: #bfd3d6;
  --primary-500: #a8c0c3; /* same as primary-color */
  --primary-600: #68888c;
  --primary-700: #3f6165;
  --primary-800: #224246;
  --primary-900: #002733;

  --primary-100--contrast: $black-color;
  --primary-200--contrast: $black-color;
  --primary-300--contrast: $black-color;
  --primary-400--contrast: $black-color;
  --primary-500--contrast: $white-color;
  --primary-600--contrast: $white-color;
  --primary-700--contrast: $white-color;
  --primary-800--contrast: $white-color;
  --primary-900--contrast: $white-color;

  --secondary-100: #fcffe5;
  --secondary-200: #f4ffa3;
  --secondary-300: #ecff75;
  --secondary-400: #e6fc5d;
  --secondary-500: #d7ed4c; /* same as secondary-color */
  --secondary-600: #afc534;
  --secondary-700: #839619;
  --secondary-800: #5b6907;
  --secondary-900: #374003;

  --secondary-100--contrast: $black-color;
  --secondary-200--contrast: $black-color;
  --secondary-300--contrast: $black-color;
  --secondary-400--contrast: $black-color;
  --secondary-500--contrast: $black-color;
  --secondary-600--contrast: $black-color;
  --secondary-700--contrast: $white-color;
  --secondary-800--contrast: $white-color;
  --secondary-900--contrast: $white-color;
}

$primary-palette: (
  100: var(--primary-100),
  200: var(--primary-200),
  300: var(--primary-300),
  400: var(--primary-400),
  500: var(--primary-500),
  600: var(--primary-600),
  700: var(--primary-700),
  800: var(--primary-800),
  900: var(--primary-900),
  contrast: (
    100: var(--primary-100--contrast),
    200: var(--primary-200--contrast),
    300: var(--primary-300--contrast),
    400: var(--primary-400--contrast),
    500: var(--primary-500--contrast),
    600: var(--primary-600--contrast),
    700: var(--primary-700--contrast),
    800: var(--primary-800--contrast),
    900: var(--primary-900--contrast),
  ),
);

$secondary-palette: (
  100: var(--secondary-100),
  200: var(--secondary-200),
  300: var(--secondary-300),
  400: var(--secondary-400),
  500: var(--secondary-500),
  600: var(--secondary-600),
  700: var(--secondary-700),
  800: var(--secondary-800),
  900: var(--secondary-900),
  contrast: (
    100: var(--secondary-100--contrast),
    200: var(--secondary-200--contrast),
    300: var(--secondary-300--contrast),
    400: var(--secondary-400--contrast),
    500: var(--secondary-500--contrast),
    600: var(--secondary-600--contrast),
    700: var(--secondary-700--contrast),
    800: var(--secondary-800--contrast),
    900: var(--secondary-900--contrast),
  ),
);

$note-palette: (
  100: #bfb3a8,
  200: #617c81,
  300: #acbec1,
  400: #695645,
  500: #1d3e42,
);

$neutral-palette: (
  100: #f8f7f3,
  200: #efede5,
  300: #d6d5ce,
  400: #c7c6bf,
  500: #adaca6,
  600: #7e7d7c,
  700: #5f5e5d,
  800: #414035,
  900: #171503,
);

$error-palette: (
  100: #fff2f1,
  200: #ffc3c0,
  300: #ff968f,
  400: #fd6150,
  500: #e61b00,
  600: #ab1400,
  700: #8b1200,
  800: #5e0c00,
  900: #4d0b07,
);

$confirm-palette: (
  100: #eaf9de,
  200: #cbffae,
  300: #a6f787,
  400: #7df75a,
  500: #18bb0c,
  600: #008a00,
  700: #067306,
  800: #07540a,
  900: #233701,
);

$inform-palette: (
  100: #e3feff,
  200: #8de9ff,
  300: #50c8fc,
  400: #2aa8f8,
  500: #0576f0,
  600: #005bd4,
  700: #0041ac,
  800: #002463,
  900: #001943,
);

$warning-palette: (
  100: #fff4eb,
  200: #ffdbbd,
  300: #ffa65a,
  400: #ff9943,
  500: #ee6d00,
  600: #c25700,
  700: #994600,
  800: #662f00,
  900: #3d1e04,
);
