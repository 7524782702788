@use 'settings' as *;

.cdk-drag-preview {
  box-sizing: border-box;
  opacity: 0.5;
  border-radius: 4px;
  padding: #{$padding}px 0;
  border: 1px solid transparent;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-dragging {
  transition:
    transform 250ms cubic-bezier(0, 0, 0.2, 1),
    border 250ms;
}

.cdk-drag-animating {
  transition:
    transform 150ms cubic-bezier(0, 0, 0.2, 1),
    border 250ms;
}

.item-list.cdk-drop-list-dragging .item:not(.cdk-drag-placeholder),
.item-list.cdk-drop-list-dragging app-workspace-section:not(.cdk-drag-placeholder) {
  transition:
    transform 250ms cubic-bezier(0, 0, 0.2, 1),
    border 250ms;
}
