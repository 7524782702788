@use 'settings' as *;

.select {
  box-sizing: border-box;
  margin-left: #{$padding * 0.5}px;
  appearance: none;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 0;
  font-size: 0.812rem;
  cursor: pointer;
  line-height: 1;
  height: 32px;
  background-color: $white-color;
  display: grid;
  grid-template-areas: 'select';
  grid-area: select;
  align-items: center;
  position: relative;

  select {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    padding: #{$padding}px #{$padding * 3}px #{$padding}px #{$padding * 1.5}px;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    position: relative;

    &:focus {
      border-bottom: none;
    }
  }

  //line for IE appearance fix
  select::-ms-expand {
    display: none;
  }

  &::after {
    grid-area: select;
    content: '';
    width: 0.6em;
    height: 0.3em;
    right: 8px;
    background-color: $black-color;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
    position: absolute;
  }
}

//END TEMP SELECT!
